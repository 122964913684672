import React, { useEffect, useState } from 'react'
import './HappyTourists.css'
import image1 from './images/happy client1.jpeg';
import image2 from './images/happy client2.jpeg';
import image3 from './images/happy client3.jpeg';
import { Button } from 'react-bootstrap';
import { BiSolidRightArrowCircle } from "react-icons/bi";


const HappyTourists = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    function calculateTimeLeft() {
        const deadline = new Date("May 31, 2024 23:59:59").getTime();
        const now = new Date().getTime();
        const difference = deadline - now;

        if (difference <= 0) {
            return {
                hours: '00',
                minutes: '00',
                seconds: '00'
            };
        }

        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return {
            hours: formatTime(hours),
            minutes: formatTime(minutes),
            seconds: formatTime(seconds)
        };
    }
    function formatTime(time) {
        return time < 10 ? `0${time}` : time;
    }
  return (
<div className='gallary'>
    <h2 className='photoshead'>Our Happy Marriages</h2>
    <div className="image-card-container">

        <div className="image-card" >
            <img src={image1} alt="Image 1" className="card-image" />
            <div className="image-info">
                <p className='client-name'>Sonu & Sahil Chavan </p>
                <p className='client-review'>Sonu and Salil uncovered their perfect life partners through our matrimony platform, and now they are basking in the joy
                 of their marital bliss, making cherished memories together. We are privileged to have been a part of their remarkable journey.</p>
            </div>
        </div>

        <div className="image-card" >
            <img src={image2} alt="Image 2" className="card-image" />
            <div className="image-info">
                <p className='client-name'>Shweta & Vinay Baje </p>
                <p className='client-review'>Vinay & Shweta discovered their ideal partners through 
                BlessLagna matrimony & their connection ignited instantly. Presently, they enjoy a deeply affectionate relationship, 
                crafting a joyful life together.</p>
            </div>
        </div>

        <div className="image-card" >
            <img src={image3} alt="Image 3" className="card-image" />
            <div className="image-info">
                <p className='client-name'>Nilesh & Nidhi Satam </p>
                <p className='client-review'>Nilesh and Nidhi found their perfect match on our matrimony website, and now they are happily married, 
                creating lifelong memories together. Love truly knows no boundaries, and we are honored to be part of their journey.</p>
            </div>
        </div>
              
    </div>
    <div className='limited-offer-footer'>
    <div className='limited-offer-content'>
        <div className='limited-offer-box'>
            <p className='limited-offer-title'> Limited Time Offer</p>
            </div>
        <div className='limited-offer-timer'>
            <div className='timer-section'>
                <span className='timer-section-digit'>{timeLeft.hours}</span>
                <span className='timer-section-label'>Hours</span>
            </div>
            <div className='timer-section'>
                <span className='timer-section-digit'>{timeLeft.minutes}</span>
                <span className='timer-section-label'>Minutes</span>
            </div>
            <div className='timer-section'>
                <span className='timer-section-digit'>{timeLeft.seconds}</span>
                <span className='timer-section-label'>Seconds</span>
            </div>
        </div>
    </div>

       
            <a href='https://wa.link/4yjasi' className='register-bt' ><Button className='register-button'><BiSolidRightArrowCircle /><span className='Register-button-text'>Register Now</span></Button></a>
     
</div>


</div>
  )
}

export default HappyTourists