import React, { useEffect, useState } from 'react'
import { IoMdCheckbox } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa6";
import './WhyChooseUsPage.css';

const WhyChooseUsPage = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    function calculateTimeLeft() {
        const deadline = new Date("May 31, 2024 23:59:59").getTime();
        const now = new Date().getTime();
        const difference = deadline - now;

        if (difference <= 0) {
            return {
                hours: '00',
                minutes: '00',
                seconds: '00'
            };
        }

        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return {
            hours: formatTime(hours),
            minutes: formatTime(minutes),
            seconds: formatTime(seconds)
        };
    }
    function formatTime(time) {
        return time < 10 ? `0${time}` : time;
    }

  return (
    
<div class="why-choose-section">
        <h2 class='whychoose-head'>Why Choose Us</h2>
        <div class="row">
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Verified Members: Our platform ensures that all members are thoroughly verified, providing a safe and trustworthy environment for your search.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'> Well Educated/Well Settled Members: We boast a community of members who are well-educated and professionally stable, enhancing compatibility prospects.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>NRI Members Available: Connect with Non-Resident Indians (NRIs) who are looking for meaningful relationships and marriage.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'> We Cover All Religions and Castes: Our service is inclusive, welcoming members from all religious and caste backgrounds.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'> We Have Divorcee/Widowed Members Too: We offer support and opportunities for divorcees and widows seeking companionship and marriage.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>20+ Years of Experience: With over two decades of experience, we have a proven track record in successful matchmaking.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Quality Support: Our dedicated support team is always available to assist you throughout your matchmaking journey.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Flexible Packages: We offer a variety of packages tailored to meet different needs and budgets.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'> Government Servants Exclusively Available: Our exclusive database includes numerous government employees, adding to the diversity of potential matches.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>We Provide 1 to 1 Meetings: Personalized meetings are arranged to help you get to know potential matches better and build a connection.</p>
                </span>
            </div>
        </div>
        <div className='countdownhead'>
        <h2 className='countdownheading'>Register Yourself</h2>
        </div>
        <div className='Countdown-container'>
            <div className='countdown-item'>
                <span className='countdown-digit'>{timeLeft.hours}</span>
                <span className='countdown-label'>Hours</span>
            </div>
            <div className='countdown-item'>
                <span  className='countdown-digit'>{timeLeft.minutes}</span>
                <span className='countdown-label'>Minutes</span>
            </div>
            <div className='countdown-item'>
                <span  className='countdown-digit'>{timeLeft.seconds}</span>
                <span className='countdown-label'>Seconds</span>
            </div>
        </div>
        <p className='arrow'>  <FaArrowDown /></p>
        <button className='contact-button'>
         <a href='https://wa.link/4yjasi'>
         <FaWhatsapp className='contact-icon' />
        <span className='contact-text'>Connect with Us</span>
         </a>
      </button>
    </div>
  )
}

export default WhyChooseUsPage