import Landingpage from "./Landingpage";
import 'bootstrap/dist/css/bootstrap.min.css';
import WhyChooseUsPage from "./WhyChooseUsPage";
import HappyTourists from "./HappyTourists";

function App() {
  return (
    <>
    <Landingpage/>
    <WhyChooseUsPage/>
    <HappyTourists/>
    </>
  );
}

export default App;
