import React, { useRef, useState } from 'react';
import './LandingPage.css'; // Import CSS file for landing page styling (if required)
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaWhatsapp } from "react-icons/fa";
import Oss1 from './images/OSS1.jpeg'
import oss2 from  './images/Oss2.jpeg'
import { FormLabel } from 'react-bootstrap';
import emailjs from 'emailjs-com';

const LandingPage = () => {
    const [showForm, setShowForm] = useState(false);

    const toggleForm = () => {
        setShowForm(!showForm);// Check if form is submitted successfully before closing the modal
      
    };
        const form =useRef();
      
        const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs.sendForm('service_7cbriby', 'template_bifl58u', form.current, '2CsqJ4WB-GGnQ6v62')
            .then((result) => {
              console.log(result.text);
              alert("Send mail Successfully")
            }, (error) => {
              console.log(error.text);
            });
          e.target.reset()
        };
    const countryCodes = [
        { code: '+1', name: 'USA' },
    { code: '+61', name: 'Australia' },
    { code: '+91', name: 'India' },
    { code: '+44', name: 'UK' },
    { code: '+86', name: 'China' },
    { code: '+81', name: 'Japan' },
    { code: '+82', name: 'South Korea' },
    { code: '+49', name: 'Germany' },
    { code: '+33', name: 'France' },
    { code: '+39', name: 'Italy' },
    { code: '+7', name: 'Russia' },
    { code: '+971', name: 'UAE' },
    { code: '+234', name: 'Nigeria' },
    { code: '+92', name: 'Pakistan' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+20', name: 'Egypt' },
    { code: '+65', name: 'Singapore' },
    { code: '+34', name: 'Spain' },
    { code: '+82', name: 'South Korea' },
    { code: '+86', name: 'China' },
    { code: '+81', name: 'Japan' },
    { code: '+62', name: 'Indonesia' },
    { code: '+63', name: 'Philippines' },
    { code: '+66', name: 'Thailand' },
    { code: '+84', name: 'Vietnam' },
    { code: '+1', name: 'Canada' },
    { code: '+55', name: 'Brazil' },
    { code: '+54', name: 'Argentina' },
    { code: '+57', name: 'Colombia' },
    { code: '+52', name: 'Mexico' },
    { code: '+58', name: 'Venezuela' },
    { code: '+51', name: 'Peru' },
    { code: '+52', name: 'Mexico' },
    { code: '+593', name: 'Ecuador' },
    { code: '+507', name: 'Panama' },
    { code: '+591', name: 'Bolivia' },
    { code: '+595', name: 'Paraguay' },
    { code: '+598', name: 'Uruguay' },
    { code: '+509', name: 'Haiti' },
    { code: '+502', name: 'Guatemala' },
    { code: '+503', name: 'El Salvador' },
    { code: '+504', name: 'Honduras' },
    { code: '+505', name: 'Nicaragua' },
    { code: '+506', name: 'Costa Rica' },
    { code: '+507', name: 'Panama' },
    { code: '+509', name: 'Haiti' },
    { code: '+507', name: 'Panama' },
    { code: '+51', name: 'Peru' },
    { code: '+54', name: 'Argentina' },
    { code: '+598', name: 'Uruguay' },
    { code: '+595', name: 'Paraguay' },
    { code: '+972', name: 'Israel' },
    { code: '+972', name: 'Israel' },
    { code: '+974', name: 'Qatar' },
    { code: '+968', name: 'Oman' },
    { code: '+962', name: 'Jordan' },
    { code: '+961', name: 'Lebanon' },
    { code: '+963', name: 'Syria' },
    { code: '+964', name: 'Iraq' },
    { code: '+962', name: 'Jordan' },
    { code: '+968', name: 'Oman' },
    { code: '+974', name: 'Qatar' },
    { code: '+967', name: 'Yemen' },
    { code: '+974', name: 'Qatar' },
    { code: '+973', name: 'Bahrain' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+965', name: 'Kuwait' },
    { code: '+967', name: 'Yemen' },
    { code: '+977', name: 'Nepal' },
    { code: '+975', name: 'Bhutan' },
    { code: '+960', name: 'Maldives' },
    { code: '+976', name: 'Mongolia' },
    { code: '+91', name: 'India' },
    { code: '+92', name: 'Pakistan' },
    { code: '+93', name: 'Afghanistan' },
    { code: '+94', name: 'Sri Lanka' },
    { code: '+95', name: 'Myanmar' },
    { code: '+98', name: 'Iran' },
    { code: '+964', name: 'Iraq' },
    { code: '+964', name: 'Iraq' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+973', name: 'Bahrain' },
    { code: '+98', name: 'Iran' },
    { code: '+961', name: 'Lebanon' },
    { code: '+963', name: 'Syria' },
    { code: '+20', name: 'Egypt' },
    { code: '+218', name: 'Libya' },
    { code: '+251', name: 'Ethiopia' },
    { code: '+252', name: 'Somalia' },
    { code: '+253', name: 'Djibouti' },
    { code: '+254', name: 'Kenya' },
    { code: '+255', name: 'Tanzania' },
    { code: '+256', name: 'Uganda' },
    { code: '+257', name: 'Burundi' },
    { code: '+258', name: 'Mozambique' },
    { code: '+260', name: 'Zambia' },
    { code: '+261', name: 'Madagascar' },
    { code: '+262', name: 'Reunion' },
    { code: '+263', name: 'Zimbabwe' },
    { code: '+264', name: 'Namibia' },
    { code: '+265', name: 'Malawi' },
    { code: '+266', name: 'Lesotho' },
    { code: '+267', name: 'Botswana' },
    { code: '+268', name: 'Swaziland' },
    { code: '+269', name: 'Comoros' },
    { code: '+27', name: 'South Africa' },
    { code: '+291', name: 'Eritrea' },
    { code: '+297', name: 'Aruba' },
    { code: '+298', name: 'Far'}
    ];
    return (
        <div className="landing-page">
            <div className="offer-container">
                <div className='whatsapp-button'>
                   <div className="whatsapp-container">
                   <a  href="https://wa.link/4yjasi">
                    <FaWhatsapp  className='whatsapp-icon'/>
                    <p className="whatsapp-text">Contact Us</p>
                   </a>
                  </div>
                </div>
                 <p className="offer-text">"भारत,अमेरिकेसह देश विदेशातील प्रत्येक मराठी घराघरात पोहोचलेली एकमेव सर्वमान्य, विश्वसनीय विवाहसंस्था!"</p>
            </div>
            <div className="content">
                <h1 className='contenthead'>"Bless Lagna Matrimony"</h1>
                <p className='parahead'>महाराष्ट्रातील सुप्रसिद्ध आणि विश्वसनीय मॅरेज ब्युरो... ठाणे(पश्चिम)

अधिकारी,डाॅक्टर,इंजिनिअर,प्राध्यापक,वकील,सी.ए, उद्योजक,प्रोफेशनल,पदवीधर इ.उच्चशिक्षित, उच्चपदस्थ व आर्थिक सुस्थापित प्रथम वधूवर,घटस्फोटीत आणि विधवा-विधूर स्थळे.</p>

                <div className="heading-container">
                    <h3 className="heading-text">"येथे लग्न १००% जुळतात"</h3>
                </div>
                

              {/* Video container code */}
              <div className="video-row">
              <img  className="video-element1" alt='blessing lagn' src={Oss1}></img>
              <img  className="video-element2" alt='blessing lagn' src={oss2}></img>                        

                  
                </div>
             <div >
                   <Button className='formbutton' onClick={toggleForm}>
                     <a   className="button-link"><span className='call-whatsapp-button-symbol'>&raquo;</span> Click Here To Register</a>
                   </Button>
                   {showForm && (
                    <div className="popup-form">
                        <span className="close-btn" onClick={toggleForm}>&times;</span>
                        <h2 className="popup-formh2">"आपल्या योग्य जोडीदाराचा शोध घेणं आता झालं आहे सोप्प..! आपली थोडी माहिती आपण खालील फॉर्म मध्ये भरावी हि विनंती.."</h2>
                        <Form  ref={form} onSubmit={sendEmail}>
                            <Form.Group className="mb-3">
                                <Form.Label>Your First Name</Form.Label>
                                <Form.Control type="text" id="fullName"
                                    name="first_name"
                                    required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" id="lastname"
                                    name="last_name"
                                    required />
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label> Your Mobile No</Form.Label>
                                <div className="input-group">
                                <select
                                className="form-select"
                                  
                                 >
                                      {countryCodes.map((country) => (
                                    <option key={country.code} value={country.code}>{`${country.code} (${country.name})`}</option>
                                          ))}
                                 </select>
                                    <Form.Control type="tel" id="phoneNumber"
                                          name="mobile"
                                        
                                        required />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <FormLabel>Your Expectations from Partner</FormLabel>
                                <textarea
                                     name="expectation"
                                     className="form-control"
                                     rows={3}
                                     required
                                  />
                            </Form.Group>

                            <Button variant="primary" className='fbutton' type="submit"  >Submit</Button>
                        </Form>
                    </div>
                )}
               
                 </div>               
            </div>
            <button  className='connect-with-us '  >
            <a href='https://wa.link/4yjasi'>
            <span className='contact-text'>Connect with Us</span></a></button>

        </div>
    );
}

export default LandingPage;
